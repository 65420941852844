var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LeaveList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "表单类型", placeholder: "请输入" },
                  model: {
                    value: _vm.searchParams.formType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "formType", $$v)
                    },
                    expression: "searchParams.formType",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "请假原因", placeholder: "请输入" },
                  model: {
                    value: _vm.searchParams.reason,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "reason", $$v)
                    },
                    expression: "searchParams.reason",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "请假天数", placeholder: "请输入" },
                  model: {
                    value: _vm.searchParams.daysOff,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "daysOff", $$v)
                    },
                    expression: "searchParams.daysOff",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                scope.row.mActHistoryApproveInfoVList.length === 0
                  ? _c("v-button", {
                      attrs: {
                        text: "发布",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.applyForm(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.getApproveTask(scope.row)
                  ? _c("v-button", {
                      attrs: {
                        text: "撤回",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.approve(scope.row, 3)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.getApproveTask(scope.row)
                  ? _c("v-button", {
                      attrs: {
                        text: "审批通过",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.approve(scope.row, 1)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.getApproveTask(scope.row)
                  ? _c("v-button", {
                      attrs: {
                        text: "审批驳回",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.approve(scope.row, 2)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.userId == _vm.getUserInfo.id
                  ? _c("v-button", {
                      attrs: {
                        text: "删除",
                        type: "text",
                        permission: "delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleDelete(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.userId == _vm.getUserInfo.id
                  ? _c("v-button", {
                      attrs: { text: "查看审批历史", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.showHistory(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-tree__node",
          attrs: {
            title: _vm.dialogText,
            width: "540px",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              key: _vm.key,
              ref: "form",
              attrs: { "label-width": "100px", model: _vm.form },
            },
            [
              _vm.dialogValue === 1
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "通过原因", prop: "approveRemarks" } },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入审批意见",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.approveRemarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "approveRemarks", $$v)
                            },
                            expression: "form.approveRemarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.dialogValue === 2
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "驳回理由", prop: "reason" } },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入驳回理由",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.reason,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "reason", $$v)
                            },
                            expression: "form.reason",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.dialogValue === 3
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "撤回理由", prop: "cancleReason" } },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入撤回理由",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.cancleReason,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cancleReason", $$v)
                            },
                            expression: "form.cancleReason",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                staticStyle: { "margin-right": "10px" },
                attrs: { text: "取 消" },
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = false
                  },
                },
              }),
              _c("v-button", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { type: "primary", text: "确 定" },
                on: { click: _vm.clickCheck },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }