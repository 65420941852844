<template>
  <div class="LeaveList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="表单类型" v-model="searchParams.formType" placeholder="请输入" />
        <v-input label="请假原因" v-model="searchParams.reason" placeholder="请输入" />
        <v-input label="请假天数" v-model="searchParams.daysOff" placeholder="请输入" />
        
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)" />
        <v-button v-if="scope.row.mActHistoryApproveInfoVList.length ===0" text="发布" type="text" permission="update" @click="applyForm(scope.row)" />
        <v-button v-if="getApproveTask(scope.row)" text="撤回" type="text" permission="update" @click="approve(scope.row,3)" />
        <v-button v-if="getApproveTask(scope.row)" text="审批通过" type="text" permission="update" @click="approve(scope.row,1)" />
        <v-button v-if="getApproveTask(scope.row)" text="审批驳回" type="text" permission="update" @click="approve(scope.row,2)" />
        <v-button v-if="scope.row.userId==getUserInfo.id" text="删除" type="text" permission="delete" @click="handleDelete(scope.row)" />
        <v-button v-if="scope.row.userId==getUserInfo.id" text="查看审批历史" type="text" @click="showHistory(scope.row)" />
      </template>
    </list>

    <el-dialog class="dialog-tree__node" :title="dialogText" width="540px" :visible.sync="dialogVisible">
      <el-form ref="form" label-width="100px" :model="form" :key="key">
        <!-- 添加或编辑组织节点、或者添加公司 -->
        <template v-if="dialogValue===1">
          <el-form-item
            label="通过原因"
            prop="approveRemarks"
          >
            <v-input placeholder="请输入审批意见" v-model="form.approveRemarks" :width="width"/>
          </el-form-item>
        </template>
        <template v-if="dialogValue===2">
          <el-form-item
            label="驳回理由"
            prop="reason"
          >
            <v-input placeholder="请输入驳回理由" v-model="form.reason" :width="width"/>
          </el-form-item>
        </template>
        <template v-if="dialogValue===3">
          <el-form-item
            label="撤回理由"
            prop="cancleReason"
          >
            <v-input placeholder="请输入撤回理由" v-model="form.cancleReason" :width="width"/>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <v-button @click="dialogVisible = false" style="margin-right:10px" text="取 消" ></v-button>
        <v-button type="primary" v-loading="loading" @click="clickCheck" text="确 定"></v-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { 
    getLeaveFormListURL,
    deleteURL,
    batchDeleteURL,
    applyFormURL,
    passFormURL,
    backFormURL,
    cancelFormURL, } from './api'
import { formStatusOps, formStatusMap } from './map'

export default {
  name: 'LeaveList',
  data () {
    return {
      searchUrl: getLeaveFormListURL,
      maxDate: new Date(),
      // 审批弹窗相关字段
      width:320,
      form: {
        approveRemarks:'',
        reason:'',
        cancleReason:''
      },
      loading: false,
      key: 0,
      dialogValue: '',
      dialogText: '',
      dialogVisible: false,
      selectData: {},
      searchParams: {
        userId: '',
        formType: '',
        reason: '',
        daysOff: '',
        updateTimeFrom: '',
        updateTimeTo: ''
      },
      headers: [
        {
          prop: 'formType',
          label: '单据类型'
        },
        {
          prop: 'daysOff',
          label: '请假天数'
        },
        {
          prop: 'startOffDate',
          label: '请假起始时间'
        },
        {
          prop: 'endOffDate',
          label: '请假结束时间'
        },
        {
          prop: 'reason',
          label: '请假原因'
        },
        {
          prop: 'status',
          label: '单据状态',
          formatter: row => formStatusMap[row.status]
        },
        {
          prop: 'createTime',
          label: '创建时间'
        }
      ]
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return userType === '106'
    },
    getUserInfo () {
      return this.$store.state.userInfo
    },
    getApproveTask () {
      return function(row) {
        let isNeedApprove = false
        for (const index in row.mActHistoryApproveInfoVList) {
          if (Object.hasOwnProperty.call(row.mActHistoryApproveInfoVList, index)) {
            const element = row.mActHistoryApproveInfoVList[index];
            if(element.actName && element.approveStatus=='处理中' && 
                element.taskId && element.approveUserId == this.getUserInfo.id) {
              isNeedApprove = true
              break
            }
          }
        }
        return isNeedApprove
      }
    }
      
  },
  watch: {
    dialogVisible (val) {
      this.key += 1
      if (!val) {
        // 弹窗关闭时，需要调一次列表接口
        this.form = {
          approveRemarks:'',
          reason:''
        }
      }
    },
  },
  mounted() {
    this.searchParams.userId = this.getUserInfo.id
  },
  methods: {
    create () {
      if (this.visible) {
        this.$router.push({
          name: 'leaveFormDetail'
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    edit (row) {
      if (this.visible) {
        console.log(row)
        this.$router.push({
          name: 'leaveFormDetail',
          query: {
            id: row.id
          }
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    async approve (row, dialogValue) {
      if(dialogValue === 3) {
        this.selectData = row
        this.dialogText = '撤回申请'
      } else if(dialogValue === 1 || dialogValue===2) {
        for (const index in row.mActHistoryApproveInfoVList) {
          if (Object.hasOwnProperty.call(row.mActHistoryApproveInfoVList, index)) {
            const element = row.mActHistoryApproveInfoVList[index];
            if(element.actName && element.approveStatus=='处理中' && 
                element.taskId && element.approveUserId == this.getUserInfo.id) {
              if(dialogValue === 1) {
                this.dialogText = '任务：' + element.actName +'审批通过'
              } else if(dialogValue === 2) {
                this.dialogText = '任务：' + element.actName +'审批驳回'
              }
              this.selectData = element
              break
            }
          }
        }
      }
      this.dialogValue = dialogValue
      this.dialogVisible = true
    },
     /** 弹框相关 */
    async clickCheck () {
      let onOff = true
      this.$refs.form.validate(result => {
        onOff = result
      })
      if (!onOff) {
        return
      }
      this.loading = true
      let result = false
      // 点击确认,总共6种情况 1添加组织节点 2查询组织节点下成员 3编辑组织节点 4移动组织节点 5删除公司 6批量移动 7删除组织节点
      if (this.dialogValue === 1) {
        result = await this.completeTask()
      } else if (this.dialogValue === 2) {
        result = await this.backProcess()
      } else if (this.dialogValue === 3) {
        result = await this.cancleForm()
      }
      
      this.loading = false
      if (result) {
        this.dialogVisible = false
        this.$refs.list.searchData()
      }
    },
    async applyForm(row) {
      const result = await this.$confirm('确认提交申请？', {
          title: '提示'
      })
      if(result) {
          let params = {
          id: row.id
        }
        this.$axios.post(applyFormURL, null, {params:params}).then(res=>{
          console.log(res)
          if(res.status == 100) {
            this.$message.success("提交申请成功")
            this.$refs.list.searchData()
          }
        })
      }
      
    },
    async completeTask() {
      let isSuccess = false
      let element = this.selectData
      await this.$axios.post(passFormURL, {
        taskId: element.taskId,
        approveRemarks: this.form.approveRemarks,
        approveStatus: "PASS",
        processInstanceId: element.procInstId,
        userId: this.getUserInfo.id,
        userName: this.getUserInfo.userName
      }).then((res) => {
        if (res.status === 100 && res.msg === 'ok') {
          isSuccess = true
          this.$message.success("审批通过成功")
        }
      })
      return isSuccess
    },
    async backProcess() {
      let isSuccess = false
      let element = this.selectData
      await this.$axios.post(passFormURL, {
        taskId: element.taskId,
        approveRemarks: this.form.reason,
        approveStatus: "REJECT",
        processInstanceId: element.procInstId,
        userId: this.getUserInfo.id,
        userName: this.getUserInfo.userName
      }).then((res) => {
        if (res.status === 100 && res.msg === 'ok') {
          isSuccess = true
          this.$message.success("审批驳回成功")
        }
      })
      return isSuccess
    },
    async cancleForm() {
      let isSuccess = false
      let row = this.selectData
      let params = {
          procInstId: row.procInstId,
          id: row.id
      }
      await this.$axios.get(cancelFormURL, { params: params }).then((res) => {
          if (res.status === 100) {
              this.$message.success('撤回成功')
              isSuccess = true
          }
      })
      return isSuccess
    },
    async handleDelete (row) {
      console.log(row)
      const result = await this.$confirm('确认删除？', {
          title: '提示'
      })
      if(result) {
          var that = this
          let params = {
              id: row.id
          }
          that.$axios.delete(deleteURL, { params: params }).then((res) => {
              if (res.status === 100) {
                  that.$message.success(res.data)
                  that.$refs.list.searchData()
              }
          })
      }
    },
    async batch (selected) {
      let result = await this.$confirm('确认删除？', {
        title: '提示'
      })
      if (result) {
        // 操作日志添加操作对象参数,组装规则：敏感词-分类
        
        const params = {
          ids: selected.ids.join(',')
        }
        const { status, data } = await this.$axios.delete(batchDeleteURL, { params })
        if (status === 100) {
          this.$message.success('删除成功')
          this.$refs.list.searchData()
        }
      }
    },
    showHistory (row) {
      this.$router.push({
        name: 'historyFlowList',
        query: {
          procInstId: row.procInstId
        }
      })
    },

  }
}
</script>
<style lang="scss" scoped>
.LeaveList-wrapper {
  .dialog-tree__node {
    text-align: left;
    .p-text {
      margin-top: 0;
    }
    .p-mar-0 {
      margin: 0;
    }
    .p-pad-l {
      padding-left: 45px;
    }
    .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

